html,
body {
  margin: 0;
}

.quote:not(:first-child)::before {
  content: "•  ";
  color: #c7c8c3;
  font-weight: 400;
}

.quote {
  font-size: 22px;
  margin-left: 6px;
  line-height: 1.7;
  text-align: justify;
}

main {
  flex-direction: row;
  display: flex;
  margin: 0;
  font-family: Archivo, sans-serif;
  overflow: hidden;
  height: 100vh;
  background-color: #000000;
}

.black {
  background-color: #000000;
  width: 480px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.list {
  flex: 1;
  text-align: justify;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}

.headshot {
  border-radius: 50%;
  margin-top: 100px;
}

.icons-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  padding-bottom: 56px;
  background-color: #000000;
}

.icons-section.middle {
  justify-content: center;
}

.heading-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.title {
  color: white;
  font-family: Archivo;
  font-weight: 900;
  margin-bottom: 8px;
  letter-spacing: 0.15em;
}

.subtitle {
  color: #c7c8c3;
  font-family: Archivo;
  margin-bottom: 80px;
}

@media screen and (max-width: 960px) {
  main {
    flex-direction: column;
    overflow: auto;
  }
  .black {
    width: 100%;
    height: min-content;
  }
  .list {
    overflow: auto;
  }

  .icons-section.left {
    display: none;
  }
  .icons-section.middle {
    display: flex;
    width: 100vw;
    bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .headshot {
    height: 150px;
    width: 150px;
    margin-top: 32px;
  }

  .heading-container {
    align-items: center;
  }
  .title {
    font-weight: 900;
    font-size: 30px;
  }
  .subtitle {
    margin-bottom: 32px;
  }
  .quote {
    font-size: 18px;
    line-height: 1.5;
  }
}

@media screen and (min-width: 960px) {
  .icons-section.middle {
    display: none;
  }
  .icons-section.left {
    display: flex;
  }
}
